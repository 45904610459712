import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-636eab5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "infoWatiosWatCoinsContainer",
  ref: "target"
}
const _hoisted_2 = { id: "header" }
const _hoisted_3 = { id: "headerTitle" }
const _hoisted_4 = { id: "nav" }
const _hoisted_5 = { id: "content" }
const _hoisted_6 = { id: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoWatDescription = _resolveComponent("InfoWatDescription")!
  const _component_InfoWatGet = _resolveComponent("InfoWatGet")!
  const _component_InfoWatStatus = _resolveComponent("InfoWatStatus")!
  const _component_InfoWatPrivileges = _resolveComponent("InfoWatPrivileges")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Testers_shared_karma_explanation_modal_how_works')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedOption = 'InfoWatDescription')),
            class: _normalizeClass({
                            selected: _ctx.selectedOption == 'InfoWatDescription',
                        })
          }, _toDisplayString(_ctx.$t('WHAT_ARE_THEY')), 3 /* TEXT, CLASS */),
          _createElementVNode("li", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedOption = 'InfoWatGet')),
            class: _normalizeClass({ selected: _ctx.selectedOption == 'InfoWatGet' })
          }, _toDisplayString(_ctx.$t('GET_THEM')), 3 /* TEXT, CLASS */),
          _createElementVNode("li", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedOption = 'InfoWatStatus')),
            class: _normalizeClass({ selected: _ctx.selectedOption == 'InfoWatStatus' })
          }, _toDisplayString(_ctx.$t('TESTER_STATUS')), 3 /* TEXT, CLASS */),
          _createElementVNode("li", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedOption = 'InfoWatPrivileges')),
            class: _normalizeClass({
                            selected: _ctx.selectedOption == 'InfoWatPrivileges',
                        })
          }, _toDisplayString(_ctx.$t('Testers_shared_karma_explanation_privileges1')), 3 /* TEXT, CLASS */)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives(_createVNode(_component_InfoWatDescription, null, null, 512 /* NEED_PATCH */), [
        [_vShow, _ctx.selectedOption == 'InfoWatDescription']
      ]),
      _withDirectives(_createVNode(_component_InfoWatGet, null, null, 512 /* NEED_PATCH */), [
        [_vShow, _ctx.selectedOption == 'InfoWatGet']
      ]),
      _withDirectives(_createVNode(_component_InfoWatStatus, null, null, 512 /* NEED_PATCH */), [
        [_vShow, _ctx.selectedOption == 'InfoWatStatus']
      ]),
      _withDirectives(_createVNode(_component_InfoWatPrivileges, null, null, 512 /* NEED_PATCH */), [
        [_vShow, _ctx.selectedOption == 'InfoWatPrivileges']
      ])
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('tester_faq')), 1 /* TEXT */)
  ], 512 /* NEED_PATCH */))
}