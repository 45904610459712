import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24df1360"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "profileMobileBoxContainer",
  ref: "target"
}
const _hoisted_2 = { id: "topProfileMobileBox" }
const _hoisted_3 = { id: "bottomProfileMobileBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePictureBox = _resolveComponent("ProfilePictureBox")!
  const _component_ProfileProgressBar = _resolveComponent("ProfileProgressBar")!
  const _component_ProfilePointsBoxMobile = _resolveComponent("ProfilePointsBoxMobile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProfilePictureBox, { id: "profilePictureBoxContainer" }),
      _createElementVNode("h1", null, _toDisplayString(_ctx.userData.nickname), 1 /* TEXT */),
      _createElementVNode("p", null, [
        _createTextVNode(_toDisplayString('Tester') + " "),
        _createElementVNode("span", null, _toDisplayString(_ctx.userData.levelStatus), 1 /* TEXT */),
        _createTextVNode(" | "),
        _createElementVNode("span", null, _toDisplayString(`${_ctx.$t('Testers_shared_karma_explanation_level')} ${_ctx.userData.level}`), 1 /* TEXT */)
      ]),
      _createVNode(_component_ProfileProgressBar, { id: "profileProgressBarContainer" }),
      _createElementVNode("p", null, _toDisplayString(_ctx.userData.pointsToNextLevel) + " " + _toDisplayString(_ctx.$t('POINTS_FOR_NEXT_LEVEL')), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ProfilePointsBoxMobile)
    ])
  ], 512 /* NEED_PATCH */))
}