import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37d43ced"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "profileProgressBarContainer" }
const _hoisted_2 = { id: "progressBar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        id: "progress",
        style: _normalizeStyle('width: '+_ctx.userData.level+'%')
      }, null, 4 /* STYLE */)
    ])
  ]))
}