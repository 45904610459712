// Ionic styles
import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

// Custom styles
import '@/theme/variables.css'
import '@/theme/_variables.scss'
import '@/theme/utilities.css'
import '@/theme/global.css'
import '@/theme/fonts.css'
import '@/theme/icons.css'

import '@/theme/_wordpress.scss'
import '@/theme/_what.scss'


