import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: '/login',
    },
    {
        path: '/app',
        component: () => import('@/components/Menu.vue'),
        redirect: '/login',
        children: [
            {
                name: 'profile',
                path: 'profile',
                component: () => import('@/views/Profile.vue'),
            },
            {
                name: 'myAccount',
                path: 'myAccount',
                component: () => import('@/views/MyAccount.vue'),
            },
            {
                name: 'personalData',
                path: 'personalData',
                component: () => import('@/views/PersonalData.vue'),
            },
            {
                name: 'ranking',
                path: 'ranking',
                component: () => import('@/views/Ranking.vue'),
            },
            {
                name: 'tests.index',
                path: 'tests',
                component: () => import('@/views/Tests.vue'),
            },
            {
                name: 'how',
                path: 'how',
                component: () => import('@/views/How.vue'),
            },
            {
                name: 'focusPreview',
                path: 'focus-preview/:id/:slotHash',
                component: () => import('@/views/WatFocusPreview.vue'),
            },
            {
                name: 'focusPreviewPublic',
                path: 'focus-preview/:id/:slotHash/:auth',
                props:{isPublic: true},
                component: () => import('@/views/WatFocusPreview.vue'),
            },
            {
                name: 'focus',
                path: 'focus/:id/:slotHash',
                component: () => import('@/views/WatFocusExecution.vue'),
            },
            {
                name: 'focusPublic',
                path: 'focus/:id/:slotHash/:auth',
                props:{isPublic: true},
                component: () => import('@/views/WatFocusExecution.vue'),
            },
            {
                name: 'watAcademy',
                path: 'watAcademy',
                component: () => import('@/views/WatAcademy.vue')
            },
            {
                name: 'what',
                path: 'what',
                component: () => import('@/views/What.vue'),
            },
            {
                name: 'what.post',
                path: 'what/:id',
                component: () => import('@/views/WhatPostScreen.vue'),
            },
            {
                name: 'record.index',
                path: 'record',
                component: () => import('@/views/Points.vue'),
            },
            {
                name: 'exchange',
                path: 'exchange',
                component: () => import('@/views/Exchange.vue'),
            },
        ],
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('@/views/auth/Login.vue'),
    },
    {
        path: '/forgot-password',
        component: () => import('@/views/auth/ForgotPassword.vue'),
    },
    {
        name:'verify-email',
        path: '/verify-email',
        component: () => import('@/views/ConfirmEmail.vue'),
    },
    {
        path: '/register',
        component: () => import('@/views/auth/Register.vue'),
    },
    {
        name: 'verify-mobile',
        path: '/verify-mobile',
        component: () => import('@/views/VerifyMobile.vue'),
    },
    {
        name:'validate2fa',
        path: '/2fa',
        component: () => import('@/views/auth/Validate2FA.vue')
    },
    {
        name: 'add-mobile',
        path: '/add-mobile',
        component: () => import('@/views/AddMobile.vue'),
    },
    {
        name: 'unique-validation',
        path: '/unique-validation',
        component: () => import('@/views/UniqueVerification.vue')
    },
    {
        name: 'test.show',
        path: '/app/test/:id/show',
        props: true,
        component: () => import('@/views/Test.vue'),
    },
    {
        name: 'focustest',
        path: '/app/test/:id/show/focus',
        props: true,
        component: () => import('@/views/Test.vue'),
    },
    {
        path: '/app/:id',
        component: () => import('@/views/Screen.vue'),
    },
    
]

export default routes
